import { useEffect } from 'react';
import styled from 'styled-components';
import { Section, TextField, Button, Sortable, Portlet } from '@hamrolt/components';
import { useForm } from 'react-hook-form';
import { Material, setNewItem } from './Material';
import { useDocument } from '../hooks/useDocument';
import { doc, DocumentReference, CollectionReference } from '../firebase';

type Order = string[];

interface CommonData {
	order: Order;
}

export interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

const defaultCommonData: CommonData = {
	order: []
};

interface LocalizedData {
	heading: string;
	subheading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	subheading: ''
};

interface FormData {
	published: boolean;
	heading: string;
};

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
	margin-bottom: 40px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

export const Materials = ({ res, className }: Props) => {
	const { data: common, update: updateCommon } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized, update: updateLocalized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	const { register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit = async ({ /*published,*/ heading }: FormData) => {
		updateLocalized({ heading });
	};

	const handleAddItem = () => {
		const newItem = setNewItem(res.items, 'pl-PL');
		updateCommon({ ...common, order: [...common.order, newItem.id] });
	};

	const handleRemoveItem = (itemId: string) => () => {
		updateCommon({ ...common, order: common.order.filter((id) => id !== itemId) });
	};

	useEffect(() => {
		// setValue('published', common.published);
		setValue('heading', localized.heading);
	}, [setValue, localized]);

	return <Section title={localized.heading} className={className}>
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Actions>
				<SaveButton type="submit">Zapisz</SaveButton>
			</Actions>
			<TextField
				id="heading"
				label="Tytuł"
				{...register('heading')}
			/>
		</Form>
		<Button onClick={handleAddItem}>Dodaj</Button>
		<Sortable
			id="opinion"
			items={common.order}
			direction="horizontal"
			onDragEnd={order => updateCommon({ order: (order as Order) })}
			renderItem={({ item: id }: any, { innerRef, ...provided }, snapshot) => {
				const dbBase = doc(res.items, id);

				return <Portlet
					{...provided}
					ref={innerRef}
					isDragging={snapshot.isDragging}
				>
					<Material key={id} res={{ base: dbBase, localized: doc(dbBase, 'localized/pl-PL') }} onRemove={handleRemoveItem(id)} />
				</Portlet>
			}} />
	</Section>
};