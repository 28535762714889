import { General } from '../components/General';
import { OpenGraph } from '../components/OpenGraph';
import { SocialChannels } from '../components/SocialChannels';
import { db, doc, collection } from '../firebase';

const dbGeneral = doc(db, 'settings/general');
const dbOpenGraph = doc(db, 'settings/og');
const dbSocialMedia = doc(db, 'settings/socialMedia');

export const Settings = () => {

	return <div>
		<General res={{ base: dbGeneral }} />
		<OpenGraph res={{ base: dbOpenGraph }} />
		<SocialChannels res={{ base: dbSocialMedia, items: collection(dbSocialMedia, 'items') }} />
	</div>
};