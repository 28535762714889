import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext, AuthContextProps } from './AuthProvider';

interface PrivateRouteProps {
	children: React.ReactNode;
	path: string;
}

export const PrivateRoute = ({ children, ...props }: PrivateRouteProps) => {
	const { currentUser } = useContext<AuthContextProps>(AuthContext);

	return <Route
		{...props}
		render={({ location }) =>
			!!currentUser
				? children
				: <Redirect to={{ pathname: '/login', state: { from: location } }} />
		}
	/>
};
