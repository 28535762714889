import { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Section, TextField, Button } from '@hamrolt/components';
import { useDocument } from '../hooks/useDocument';
import { Poster } from './Poster';
import { doc, DocumentReference, CollectionReference } from '../firebase';

export interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

interface LocalizedData {
	heading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: ''
};

interface FormData {
	published: boolean;
	heading: string;
};

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
	margin-bottom: 40px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const Posters = styled.div`
	display: grid;
	grid-gap: 48px;
	justify-content: center;
	grid-template-columns: repeat(2, 540px);
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

export const Space = ({ res, className }: Props) => {
	const { data: localized, update: updateLocalized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	const { register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit = async ({ /*published,*/ heading }: FormData) => {
		updateLocalized({ heading });
	};

	useEffect(() => {
		// setValue('published', common.published);
		setValue('heading', localized.heading);
	}, [setValue, localized]);

	return <Section title={localized.heading} className={className}>
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Actions>
				<SaveButton type="submit">Zapisz</SaveButton>
			</Actions>
			<TextField
				id="heading"
				label="Tytuł"
				{...register('heading')}
			/>
		</Form>
		<Posters>
			<Poster res={{ base: doc(res.items, 'mentoring'), localized: doc(res.items, 'mentoring/localized/pl-PL') }} />
			<Poster res={{ base: doc(res.items, 'communication'), localized: doc(res.items, 'communication/localized/pl-PL') }} />
		</Posters>
	</Section>
};