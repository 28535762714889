import { useEffect } from 'react';
import styled from 'styled-components';
import { Button, /*Switch,*/ TextField, RichText, EditorState, convertToRaw, convertFromRaw, RawDraftContentState } from '@hamrolt/components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ImageUpload } from './ImageUpload';
import { FileUpload } from './FileUpload';
import draftToHtml from 'draftjs-to-html';
import { useFile, FileData, fileDefaultData } from '../hooks/useFile';
import { useDocument } from '../hooks/useDocument';
import { doc, setDoc, DocumentReference, CollectionReference } from '../firebase';

interface LocalizedData {
	heading: string;
	label: string;
	imageAlt: string;
	teaser: RawDraftContentState;
	teaserHTML: string;
	body: RawDraftContentState;
	bodyHTML: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	label: '',
	imageAlt: '',
	teaser: convertToRaw(EditorState.createEmpty().getCurrentContent()),
	teaserHTML: '',
	body: convertToRaw(EditorState.createEmpty().getCurrentContent()),
	bodyHTML: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
	material: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData,
	material: fileDefaultData
};

export interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
	onRemove?: () => void;
}

interface FormData {
	published: boolean;
	heading: string;
	label: string;
	teaser: EditorState;
	body: EditorState;
	image: FileData;
	imageAlt: string;
	material: FileData;
};

export const setNewItem = (collRef: CollectionReference, locale: string) => {
	const newDoc = doc(collRef);
	setDoc(newDoc, defaultCommonData);
	setDoc(doc(newDoc, 'localized', locale), defaultLocalizedData);
	return newDoc;
};

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
	width: 360px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

const DeleteButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

// const PublishedSwitch = styled(Switch)`
// 	display: flex;
// `;

export const Product = ({ res, className, onRemove }: Props) => {
	const { data: common, update: updateCommon, remove: removeCommon } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized, update: updateLocalized, remove: removeLocalized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const { update: updateImage, remove: removeImage } = useFile('image', res.base.path);
	const { update: updateMaterial, remove: removeMaterial } = useFile('material', res.base.path);

	const { control, register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ published, heading, label, teaser, body, image, imageAlt, material }) => {
		const imageData = await updateImage(image as FileData);
		const materialData = await updateMaterial(material as FileData);
		const teaserRaw = convertToRaw((teaser as EditorState).getCurrentContent());
		const bodyRaw = convertToRaw((body as EditorState).getCurrentContent());
		updateCommon({ /*published,*/ image: imageData, material: materialData });
		updateLocalized({ heading, label, imageAlt, teaser: teaserRaw, teaserHTML: draftToHtml(teaserRaw), body: bodyRaw, bodyHTML: draftToHtml(bodyRaw) });
	};

	const handleDelete = () => {
		removeLocalized();
		removeCommon();
		if (common.image.path) {
			removeImage(common.image.path);
		}
		if (common.material.path) {
			removeMaterial(common.material.path);
		}
		onRemove && onRemove();
	};

	useEffect(() => {
		setValue('heading', localized.heading);
		setValue('label', localized.label);
		setValue('imageAlt', localized.imageAlt);
		setValue('teaser', EditorState.createWithContent(convertFromRaw(localized.teaser)));
		setValue('body', EditorState.createWithContent(convertFromRaw(localized.body)));
		// setValue('published', common.published);
		setValue('image', common.image);
		setValue('material', common.material);
	}, [setValue, localized, common]);

	return <Form className={className} onSubmit={handleSubmit(onSubmit)}>
		<Actions>
			<SaveButton type="submit">Zapisz</SaveButton>
			{onRemove && <DeleteButton type="button" onClick={handleDelete}>Usuń</DeleteButton>}
		</Actions>
		<Controller
			name="image"
			control={control}
			render={({ field: { value, onChange } }) => <ImageUpload id="image" label="Ilustracja" value={value as FileData} onChange={onChange} />}
		/>
		<TextField
			id="imageAlt"
			label="Alt Text"
			{...register('imageAlt')}
		/>
		<Controller
			name="material"
			control={control}
			render={({ field: { value, onChange } }) => <FileUpload label="Materiał" accept="application/pdf" value={value as FileData} onChange={onChange} />}
		/>
		{/* <PublishedSwitch
			label="Opublikowany"
			{...register('published')}
		/> */}
		<TextField
			id="heading"
			label="Tytuł"
			{...register('heading')}
		/>
		<TextField
			id="label"
			label="Etykieta"
			{...register('label')}
		/>
		<Controller
			name="teaser"
			control={control}
			render={({ field: { value, onChange } }) => <RichText id="teaser" label="Wstępniak" value={value as EditorState} onChange={onChange} />}
		/>
		<Controller
			name="body"
			control={control}
			render={({ field: { value, onChange } }) => <RichText id="body" label="Opis" value={value as EditorState} onChange={onChange} />}
		/>
	</Form>
}