import { useEffect } from 'react';
import styled from 'styled-components';
import { Section } from '@hamrolt/components';
import { Button, TextField } from '@hamrolt/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference } from '../firebase';

interface CommonData {
	title: string;
	description: string;
	keywords: string;
}

const defaultCommonData: CommonData = {
	title: '',
	description: '',
	keywords: ''
};

interface Resources {
	base: DocumentReference;
}

export interface Props {
	res: Resources;
	className?: string;
}

interface FormData {
	title: string;
	description: string;
	keywords: string;
};

const Form = styled.form`
	display: grid;
	grid-gap: 32px;
	margin-bottom: 16px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ type: 'contained', startIcon: { name: 'save' } })};
`;

export const General = ({ res, className }: Props) => {
	const { data: common, update: updateCommon } = useDocument<CommonData>(res.base, defaultCommonData);

	const { register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ title, description, keywords }) => {
		updateCommon({ title, description, keywords });
	};

	useEffect(() => {
		setValue('title', common.title);
		setValue('description', common.description);
		setValue('keywords', common.keywords);
	}, [setValue, common]);

	return <Section title="General" className={className}>
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Actions>
				<SaveButton type="submit">Zapisz</SaveButton>
			</Actions>
			<TextField
				id="title"
				label="Title"
				{...register('title')}
			/>
			<TextField
				id="description"
				label="Description"
				{...register('description')}
			/>
			<TextField
				id="keywords"
				label="Keywords"
				{...register('keywords')}
			/>
		</Form>
	</Section>
}