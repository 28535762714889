import React, { useEffect, useState, createContext } from 'react';
import { auth, onAuthStateChanged, FirebaseUser } from './firebase';

interface AuthProviderProps {
	children: React.ReactNode;
}

export interface AuthContextProps {
	currentUser: User;
}

export type User = FirebaseUser | null;

export const AuthContext = createContext<AuthContextProps>({ currentUser: null });

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [currentUser, setCurrentUser] = useState<User>(null);
	const [pending, setPending] = useState(true);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			setCurrentUser(user);
			setPending(false);
		});
		return unsubscribe;
	}, []);

	return pending
		? <div>Loading...</div> //TODO add visual loader
		: <AuthContext.Provider value={{ currentUser }}>
			{children}
		</AuthContext.Provider>
};
