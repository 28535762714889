import { useEffect } from 'react';
import styled from 'styled-components';
import { Button, /*Switch,*/ TextField, LabelledContainer } from '@hamrolt/components';
import { Positioner, Direction as PositionerDirection, Definitions as PositionerDefinitions } from './Positioner';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ImageUpload } from './ImageUpload';
import { useFile, FileData, fileDefaultData } from '../hooks/useFile';
import { useDocument } from '../hooks/useDocument';
import { useDropdown } from '../hooks/useDropdown';
import { doc, setDoc, DocumentReference, CollectionReference } from '../firebase';

type Background = 'dark' | 'none';
type Width = '33' | '50' | '75' | '100';

const positionerDefinitions: PositionerDefinitions = {
	nw: { position: { h: 'start', v: 'start' }, label: 'NW' },
	n: { position: { h: 'center', v: 'start' }, label: 'N' },
	ne: { position: { h: 'end', v: 'start' }, label: 'NE' },
	e: { position: { h: 'start', v: 'center' }, label: 'E' },
	m: { position: { h: 'center', v: 'center' }, label: 'M' },
	w: { position: { h: 'end', v: 'center' }, label: 'W' },
	se: { position: { h: 'start', v: 'end' }, label: 'SE' },
	s: { position: { h: 'center', v: 'end' }, label: 'S' },
	sw: { position: { h: 'end', v: 'end' }, label: 'SW' }
};

const backgroundDefinitions: Record<Background, { label: string }> = {
	dark: { label: 'Ciemne' },
	none: { label: 'Brak' }
};

const widthDefinitions: Record<Width, { label: string }> = {
	'33': { label: '33%' },
	'50': { label: '50%' },
	'75': { label: '75%' },
	'100': { label: '100%' }
};

interface LocalizedData {
	body: string;
	imageAlt: string;
}

const defaultLocalizedData: LocalizedData = {
	body: '',
	imageAlt: ''
};

interface CommonData {
	published: boolean;
	position: PositionerDirection;
	background: Background;
	width: Width;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	position: 'm',
	background: 'none',
	width: '100',
	image: fileDefaultData
};

export interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
	onRemove?: () => void;
}

interface FormData {
	published: boolean;
	body: string;
	position: PositionerDirection;
	background: Background;
	width: Width;
	image: FileData;
	imageAlt: string;
};

export const setNewItem = (collRef: CollectionReference, locale: string) => {
	const newDoc = doc(collRef);
	setDoc(newDoc, defaultCommonData);
	setDoc(doc(newDoc, 'localized', locale), defaultLocalizedData);
	return newDoc;
};

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const Verbatim = styled.div`
	display: grid;
	grid-gap: 16px;
	align-items: start;
	grid-template-columns: auto 112px 112px 112px;
`;

const DropDownContent = styled.div`
	padding: 16px;
	border-radius: 4px;
	background-color: #fff;
	z-index: 100;
	${({ theme }) => theme.elevation.level3};
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

const DeleteButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

const DropdownTrigger = styled(Button)`
	${({ theme }) => theme.buttonContained({ iconEnd: { name: 'expand_more' } })};
	width: 100%;
	text-align: left;
	text-transform: uppercase;
`;

const Option = styled.button<{ $selected: boolean; }>`
	${({ theme }) => theme.clickableListItem({ selected: { color: 'secondary' } })};
	margin: 0 -16px;
`;
// const PublishedSwitch = styled(Switch)`
// 	display: flex;
// `;

export const Slide = ({ res, className, onRemove }: Props) => {
	const { data: common, update: updateCommon, remove: removeCommon } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized, update: updateLocalized, remove: removeLocalized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const { update: updateImage, remove: removeFile } = useFile('image', res.base.path);
	const {
		setContentVisible: setPositionerVisible,
		setTriggerRef: setPositionerTriggerRef,
		setContentRef: setPositionerContentRef,
		contentVisible: positionerContentVisible,
		contentStyle: positionerContentStyle
	} = useDropdown();
	const {
		setContentVisible: setBackgroundVisible,
		setTriggerRef: setBackgroundTriggerRef,
		setContentRef: setBackgroundContentRef,
		contentVisible: backgroundContentVisible,
		contentStyle: backgroundContentStyle
	} = useDropdown();
	const {
		setContentVisible: setWidthVisible,
		setTriggerRef: setWidthTriggerRef,
		setContentRef: setWidthContentRef,
		contentVisible: widthContentVisible,
		contentStyle: widthContentStyle
	} = useDropdown();

	const { control, register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ published, body, position, background, width, image, imageAlt }) => {
		const imageData = await updateImage(image as FileData);
		updateCommon({ /*published,*/ position, background, width, image: imageData });
		updateLocalized({ body, imageAlt });
	};

	const handleDelete = () => {
		removeLocalized();
		removeCommon();
		console.log(common.image.url);
		if (common.image.path) {
			removeFile(common.image.path);
		}
		onRemove && onRemove();
	};

	useEffect(() => {
		setValue('body', localized.body);
		setValue('imageAlt', localized.imageAlt);
		setValue('position', common.position);
		setValue('background', common.background);
		setValue('width', common.width);
		// setValue('published', common.published);
		setValue('image', common.image);
	}, [setValue, localized, common]);

	return <Form className={className} onSubmit={handleSubmit(onSubmit)}>
		<Actions>
			<SaveButton type="submit">Zapisz</SaveButton>
			{onRemove && <DeleteButton type="button" onClick={handleDelete}>Usuń</DeleteButton>}
		</Actions>
		<Verbatim>
			<TextField
				id="body"
				label="Tekst"
				{...register('body')}
			/>
			<Controller
				name="position"
				control={control}
				render={({ field: { value, onChange } }) =>
					<>
						<LabelledContainer forId="positioner" label="Pozycja">
							<DropdownTrigger
								type="button"
								ref={setPositionerTriggerRef}
								onClick={() => setPositionerVisible(!positionerContentVisible)}
							>
								{positionerDefinitions[value]?.label}
							</DropdownTrigger>
						</LabelledContainer>
						{positionerContentVisible &&
							<DropDownContent ref={setPositionerContentRef} style={positionerContentStyle}>
								<Positioner items={positionerDefinitions} selection={value} onChange={id => { onChange(id); setPositionerVisible(false); }} />
							</DropDownContent>
						}
					</>
				}
			/>
			<Controller
				name="background"
				control={control}
				render={({ field: { value, onChange } }) =>
					<>
						<LabelledContainer forId="background" label="Tło">
							<DropdownTrigger
								type="button"
								ref={setBackgroundTriggerRef}
								onClick={() => setBackgroundVisible(!backgroundContentVisible)}
							>
								{backgroundDefinitions[value]?.label}
							</DropdownTrigger>
						</LabelledContainer>
						{backgroundContentVisible &&
							<DropDownContent ref={setBackgroundContentRef} style={backgroundContentStyle}>
								{Object.entries(backgroundDefinitions).map(([id, { label }]) =>
									<Option
										key={id}
										type="button"
										$selected={id === value}
										onClick={() => { onChange(id); setBackgroundVisible(false) }}
									>
										{label}
									</Option>
								)}
							</DropDownContent>
						}
					</>
				}
			/>
			<Controller
				name="width"
				control={control}
				render={({ field: { value, onChange } }) =>
					<>
						<LabelledContainer forId="width" label="Szerokość">
							<DropdownTrigger
								type="button"
								ref={setWidthTriggerRef}
								onClick={() => setWidthVisible(!widthContentVisible)}
							>
								{value}
							</DropdownTrigger>
						</LabelledContainer>
						{widthContentVisible &&
							<DropDownContent ref={setWidthContentRef} style={widthContentStyle}>
								{Object.entries(widthDefinitions).map(([id, { label }]) =>
									<Option
										key={id}
										type="button"
										$selected={id === value}
										onClick={() => { onChange(id); setWidthVisible(false) }}
									>
										{label}
									</Option>
								)}
							</DropDownContent>
						}
					</>
				}
			/>
		</Verbatim>
		<Controller
			name="image"
			control={control}
			render={({ field: { value, onChange } }) => <ImageUpload id="image" label="Ilustracja" value={value as FileData} onChange={onChange} />}
		/>
		<TextField
			id="imageAlt"
			label="Alt Text"
			{...register('imageAlt')}
		/>
		{/* <PublishedSwitch
			label="Opublikowany"
			{...register('published')}
		/> */}
	</Form>
}