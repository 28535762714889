import { useEffect } from 'react';
import styled from 'styled-components';
import { Button, /*Switch,*/ TextField, RichText, EditorState, convertToRaw, convertFromRaw, RawDraftContentState } from '@hamrolt/components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ImageUpload } from './ImageUpload';
import draftToHtml from 'draftjs-to-html';
import { useFile, FileData, fileDefaultData } from '../hooks/useFile';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference } from '../firebase';

interface LocalizedData {
	heading: string;
	body: RawDraftContentState;
	bodyHTML: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	body: convertToRaw(EditorState.createEmpty().getCurrentContent()),
	bodyHTML: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData
};

export interface ArticleProps {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

interface FormData {
	published: boolean;
	heading: string;
	body: EditorState;
	image: FileData;
};

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
	padding: 16px;
	border-radius: 8px;
	background-color: #f4f4f4;
`;

const Description = styled(RichText)``;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

// const PublishedSwitch = styled(Switch)`
// 	display: flex;
// `;

export const ImageArticle = ({ res, className }: ArticleProps) => {
	const { data: common, update: updateCommon } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized, update: updateLocalized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const { update: updateImage } = useFile('image', res.base.path);

	const { control, register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ published, heading, body, image }) => {
		const imageData = await updateImage(image as FileData);
		const bodyRaw = convertToRaw((body as EditorState).getCurrentContent());
		updateCommon({ /*published,*/ image: imageData });
		updateLocalized({ heading, body: bodyRaw, bodyHTML: draftToHtml(bodyRaw) });
	};

	useEffect(() => {
		setValue('heading', localized.heading);
		setValue('body', EditorState.createWithContent(convertFromRaw(localized.body)));
		// setValue('published', common.published);
		setValue('image', common.image);
	}, [setValue, localized, common]);

	return <Form className={className} onSubmit={handleSubmit(onSubmit)}>
		<Actions>
			<SaveButton type="submit">Zapisz</SaveButton>
		</Actions>
		<Controller
			name="image"
			control={control}
			render={({ field: { value, onChange } }) => <ImageUpload id="image" label="Ilustracja" value={value as FileData} onChange={onChange} />}
		/>
		{/* <PublishedSwitch
			label="Opublikowany"
			{...register('published')}
		/> */}
		<TextField
			id="heading"
			label="Tytuł"
			{...register('heading')}
		/>
		<Controller
			name="body"
			control={control}
			render={({ field: { value, onChange } }) => <Description id="body" label="Opis" value={value as EditorState} onChange={onChange} />}
		/>
	</Form>
}