

import { useEffect } from 'react';
import styled from 'styled-components';
import { Button, /*Switch,*/ TextField } from '@hamrolt/components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ImageUpload } from './ImageUpload';
import { useFile, FileData, fileDefaultData } from '../hooks/useFile';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference } from '../firebase';

interface LocalizedData {
	phone: string;
	email: string;
}

const defaultLocalizedData: LocalizedData = {
	phone: '',
	email: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData
};

export interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

interface FormData {
	published: boolean;
	phone: string;
	email: string;
	image: FileData;
};

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
	padding: 16px;
	border-radius: 8px;
	background-color: #f4f4f4;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

// const PublishedSwitch = styled(Switch)`
// 	display: flex;
// `;

export const Details = ({ res, className }: Props) => {
	const { data: common, update: updateCommon } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized, update: updateLocalized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const { update: updateImage } = useFile('image', res.base.path);

	const { control, register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ published, phone, email, image }) => {
		const imageData = await updateImage(image as FileData);
		updateCommon({ /*published,*/ image: imageData });
		updateLocalized({ phone, email });
	};

	useEffect(() => {
		setValue('phone', localized.phone);
		setValue('email', localized.email);
		// setValue('published', common.published);
		setValue('image', common.image);
	}, [setValue, localized, common]);

	return <Form className={className} onSubmit={handleSubmit(onSubmit)}>
		<Actions>
			<SaveButton type="submit">Zapisz</SaveButton>
		</Actions>
		<TextField
			id="phone"
			label="Telefon"
			{...register('phone')}
		/>
		<TextField
			id="email"
			label="Email"
			{...register('email')}
		/>
		<Controller
			name="image"
			control={control}
			render={({ field: { value, onChange } }) => <ImageUpload id="image" label="Ilustracja" value={value as FileData} onChange={onChange} />}
		/>
		{/* <PublishedSwitch
			label="Opublikowany"
			{...register('published')}
		/> */}
	</Form>
}