import { css } from 'styled-components';

export type Category = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'button' | 'caption' | 'overline';
export type Weight = 'light' | 'regular' | 'medium';

const defs: Record<Category, {weight: Weight, size: number, spacing: number}> = {
	h1: {weight: 'light', size: 96, spacing: -1.5},
	h2: {weight: 'light', size: 60, spacing: -0.5},
	h3: {weight: 'regular', size: 48, spacing: 0},
	h4: {weight: 'regular', size: 34, spacing: 0.25},
	h5: {weight: 'regular', size: 24, spacing: 0},
	h6: {weight: 'medium', size: 20, spacing: 0.15},
	subtitle1: {weight: 'regular', size: 16, spacing: 0.15},
	subtitle2: {weight: 'medium', size: 14, spacing: 0.1},
	body1: {weight: 'regular', size: 16, spacing: 0.5},
	body2: {weight: 'regular', size: 14, spacing: 0.25},
	button: {weight: 'medium', size: 14, spacing: 1.25},
	caption: {weight: 'regular', size: 12, spacing: 0.4},
	overline: {weight: 'regular', size: 10, spacing: 1.5}
};

const size = 16;
const ratio = 0.0625;

export const getTypography = (category: Category) => css`
	font-family: Roboto, sans-serif;
	font-size: ${defs[category].size * ratio}rem;
	letter-spacing: ${defs[category].spacing / size}rem;
`;

export const heading1 = css`
  font-size: 96px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
`;

export const heading2 = css`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
`;

export const heading3 = css`
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0;
`;

export const heading4 = css`
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
`;

export const heading5 = css`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
`;

export const body1 = css`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

export const body2 = css`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
`;

export const subtitle1 = css`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
`;

export const caption = css`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

export const button = css`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
`;

export const listSubheader = css`
    font-size: 0.875rem;
    font-weight: 500;
`;

export const typography = {
  body1,
  body2,
  subtitle1,
  heading3,
  heading4,
  heading5,
  caption,
  button
}