import { Section, Button, Sortable, Portlet } from '@hamrolt/components';
import { Slide, setNewItem } from './Slide';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference, CollectionReference } from '../firebase';
import { doc } from 'firebase/firestore';

type Order = string[];

interface CommonData {
	order: Order;
}

export interface Props {
	res: {
		base: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

const defaultCommonData: CommonData = {
	order: []
};

export const Hero = ({ res, className }: Props) => {
	const { data: common, update: updateCommon } = useDocument<CommonData>(res.base, defaultCommonData);

	const handleAddItem = () => {
		const newItem = setNewItem(res.items, 'pl-PL');
		updateCommon({ ...common, order: [...common.order, newItem.id] });
	};

	const handleRemoveItem = (itemId: string) => () => {
		updateCommon({ ...common, order: common.order.filter((id) => id !== itemId) });
	};

	return <Section title="Hero" className={className}>
		<Button onClick={handleAddItem}>Dodaj</Button>
		<Sortable
			id="hero"
			items={common.order}
			onDragEnd={order => updateCommon({ order: (order as Order) })}
			renderItem={({ item: id }: any, { innerRef, ...provided }, snapshot) => {
				const dbBase = doc(res.items, id);

				return <Portlet
					{...provided}
					ref={innerRef}
					isDragging={snapshot.isDragging}
				>
					<Slide key={id} res={{ base: dbBase, localized: doc(dbBase, 'localized/pl-PL') }} onRemove={handleRemoveItem(id)} />
				</Portlet>
			}} />
	</Section>;
};