import React from 'react';
import styled from 'styled-components';
import { UploadArea } from '@hamrolt/components';
import { FileData, fileDefaultData } from '../hooks/useFile';

interface FileUploadProps {
	label: string;
	value: FileData;
	accept?: string;
	onChange: (file: FileData) => void;
}

interface InfoProps {
	file: FileData;
}

const Message = styled.p`
	margin: 0;
	line-height: 1.3;
`;

const List = styled.dl`
	display: grid;
	grid-gap: 4px 8px;
	align-items: end;
	grid-template-columns: auto 1fr auto 1fr;
	margin: 0;
`;

const Term = styled.dt`
	font-weight: bold;
	text-align: right;

	&:after {
		content: ':';
	}
`;

const Data = styled.dt`
`;

const Info = ({ file }: InfoProps) =>
	<List>
		<Term>nazwa</Term>
		<Data>{file.name}</Data>
		<Term>rozmiar</Term>
		<Data>{file.size && Math.round(file.size / 1000)} kB</Data>
		<Term>typ</Term>
		<Data>{file.type}</Data>
		<Term>dodany</Term>
		<Data>{new Date(file.uploadDate).toLocaleDateString()}</Data>
	</List>

export const FileUpload = React.forwardRef(({ label, value = fileDefaultData, accept, onChange }: FileUploadProps, ref) => {

	const handleDrop = (files: any) => {
		if (files.length !== 0) {
			onChange(files[0]);
		}
	}

	//TODO change to component Label and set htmlFor to UploadArea id
	return <div>
		<label>{label}</label>
		<UploadArea
			onDrop={handleDrop}
			maxFiles={1}
			accept={accept}
		>
			{value?.size
				? <Info file={value} />
				: <Message>Przeciągnij i upuść plik lub kliknij aby wybrać z dysku</Message>
			}
		</UploadArea>
	</div>
});