import styled from 'styled-components';
import { Link as RouterLink, withRouter, RouterProps } from 'react-router-dom';
interface NavigationProps extends RouterProps {
	className?: string;
}

const navMap = [
	{ id: 'home', label: 'Home', path: '/home' },
	{ id: 'mentoring', label: 'Mentoring i Szkolenia', path: '/mentoring' },
	{ id: 'communication', label: 'PR i Komunikacja', path: '/communication' },
	{ id: 'about', label: 'O mnie', path: '/about' },
	{ id: 'privacy-policy', label: 'Polityka prywatności', path: '/privacy-policy' },
	{ id: 'sale-statute', label: 'Regulamin sprzedaży', path: '/sale-statute' },
	{ id: 'settings', label: 'Ustawienia', path: '/settings' }
];

const Tabs = styled.ul`
  display: grid;
	grid-gap: 24px;
	grid-auto-flow: column;
	justify-content: center;
	list-style: none;
`;

const Link = styled(RouterLink)`
	color: inherit;
	font: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

export const Navigation = withRouter(({ className, history }: NavigationProps) => {
	return (
		<Tabs
			className={className}
			onClick={() => history.push(history.location.pathname.split('/')[1])}
		>
			{navMap.map((item) => (
				<li key={item.id}>
					<Link to={item.path}>{item.label}</Link>
				</li>
			))}
		</Tabs>
	);
});
