import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth, User } from "firebase/auth";
import { getStorage, StorageReference } from "firebase/storage";

export type FirebaseUser = User;

export {signInWithEmailAndPassword, onAuthStateChanged} from "firebase/auth";

export { 
	doc,
	addDoc,
	getDoc,
	setDoc, 
	deleteDoc, 
	collection,
	onSnapshot, 
	DocumentReference,
	DocumentSnapshot,
	CollectionReference
} from 'firebase/firestore';

export { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
export type StorageRef = StorageReference;

initializeApp({
  apiKey: "AIzaSyAaKLwkAdzBOnj854K1reZiLspdXd9qT2o",
  authDomain: "projekt-ij.firebaseapp.com",
  projectId: "projekt-ij",
  storageBucket: "projekt-ij.appspot.com",
  messagingSenderId: "683967621325",
  appId: "1:683967621325:web:512c96003ea2ce2a65e184",
	measurementId: "G-29X6Y06L5D"
});

export const analytics = getAnalytics();
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage();