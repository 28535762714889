import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { UploadArea, Image as ImageBase, LabelledContainer } from '@hamrolt/components';
import { FileData, fileDefaultData } from '../hooks/useFile';

interface ImageUploadProps {
	id: string;
	label: string;
	value: FileData;
	onChange: (file: FileData) => void;
}

const Image = styled(ImageBase)`
	max-width: 100%;
`;

const Message = styled.p`
`;

export const ImageUpload = React.forwardRef(({ id, label, value = fileDefaultData, onChange }: ImageUploadProps, ref) => {
	const [preview, setPreview] = useState('');

	const handleDrop = (files: any) => {
		if (files.length !== 0) {
			setPreview(URL.createObjectURL(files[0]));
			onChange(files[0]);
		}
	}

	useEffect(() => {
		return () => URL.revokeObjectURL(preview);
	}, [preview]);

	const src = value.url || preview;
	//TODO change to component Label and set htmlFor to UploadArea id
	return <LabelledContainer forId={id} label={label}>
		<UploadArea
			onDrop={handleDrop}
			maxFiles={1}
			accept='image/jpeg, image/jpg'
		>
			{src
				? <Image src={src} alt="" />
				: <Message>Przeciągnij i upuść plik ze zdjęciem lub kliknij aby wybrać z dysku</Message>
			}
		</UploadArea>
	</LabelledContainer>
});