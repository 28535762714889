import {css} from 'styled-components';

type Color = 'default' | 'primary' | 'secondary';

interface Props {
	selected: {
		color: Color;
	}
}

export const list = () => css`
	list-style: none;
	margin: 0;
	padding: 16px 0;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
`;

export const listItem = () => css`
	width: 100%;
	min-width: 166px;
	margin: 0;
	padding: 10px 16px;
	border: none;
	text-align: left;
`;

export const clickableListItem = ({selected}: Props) => css<{ $selected: boolean; }>`
	display: block;
	width: 100%;
	min-width: 166px;
	margin: 0;
	padding: 10px 16px;
	color: ${({ $selected, theme }) => theme.palette[$selected ? selected.color : 'default'].contrastText};
	text-align: left;
	border: none;
	background: ${({ $selected, theme }) => $selected ? theme.palette[selected.color].main : 'none'};
	cursor: ${({ $selected }) => $selected ? 'default' : 'pointer'};
	pointer-events: ${({ $selected }) => $selected ? 'none' : 'auto'};

	&:hover {
		background-color: #f5f5f5;
	}
`;