import { useEffect } from 'react';
import styled from 'styled-components';
import { Button, /*Switch,*/ } from '@hamrolt/components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ImageUpload } from './ImageUpload';
import { useFile, FileData, fileDefaultData } from '../hooks/useFile';
import { useDocument } from '../hooks/useDocument';
import { doc, setDoc, DocumentReference, CollectionReference } from '../firebase';

interface CommonData {
	published: boolean;
	image: FileData;
}

export const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
	onRemove?: () => void;
}

interface FormData {
	published: boolean;
	image: FileData;
};

export const setNewItem = (collRef: CollectionReference) => {
	const newDoc = doc(collRef);
	setDoc(newDoc, defaultCommonData);
	return newDoc;
};

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

const DeleteButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

// const PublishedSwitch = styled(Switch)`
// 	display: flex;
// `;

export const Customer = ({ res, className, onRemove }: Props) => {
	const { data: common, update: updateCommon, remove: removeCommon } = useDocument<CommonData>(res.base, defaultCommonData);
	const { update: updateImage, remove: removeFile } = useFile('image', res.base.path);

	const { control, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ published, image }) => {
		const imageData = await updateImage(image as FileData);
		updateCommon({ /*published,*/ image: imageData });
	};

	const handleDelete = () => {
		removeCommon();
		console.log(common.image.url);
		if (common.image.path) {
			removeFile(common.image.path);
		}
		onRemove && onRemove();
	};

	useEffect(() => {
		// setValue('published', common.published);
		setValue('image', common.image);
	}, [setValue, common]);

	return <Form className={className} onSubmit={handleSubmit(onSubmit)}>
		<Actions>
			<SaveButton type="submit">Zapisz</SaveButton>
			{onRemove && <DeleteButton type="button" onClick={handleDelete}>Usuń</DeleteButton>}
		</Actions>
		<Controller
			name="image"
			control={control}
			render={({ field: { value, onChange } }) => <ImageUpload id="image" label="Ilustracja" value={value as FileData} onChange={onChange} />}
		/>
		{/* <PublishedSwitch
			label="Opublikowany"
			{...register('published')}
		/> */}
	</Form>
}