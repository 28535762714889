import { css } from 'styled-components';
import { getIcon, IconNames } from './icons';
import { getTypography } from './typography';

type Size = 'small' | 'medium' | 'large';
type Color = 'default' | 'primary' | 'secondary';
type IconDef = {
	name: IconNames;
	color?: string;
};

export interface ButtonProps {
  variant?: 'text' | 'outlined' | 'contained';
  size?: Size;
  color?: Color;
  iconLeft?: {
    name: IconNames;
    color?: string;
  },
  iconRight?: {
    name: IconNames;
    color?: string;
  }
}

interface Props {
	color: Color;
	size: Size;
	iconStart?: IconDef,
  iconEnd?: IconDef
}

const allPaddings = {
	small: {padding: {h: 5, v: 4}, icon: {size: 18, padding: 29, margin: 4}},
	medium: {padding: {h: 16, v: 12}, icon: {size: 20, padding: 37, margin: 10}},
	large: {padding: {h: 20, v: 10}, icon: {size: 22, padding: 37, margin: 8}}
};

const getPadding = (size: Size='medium', iconStart: boolean, iconEnd: boolean) => {
	const {padding: {h, v}, icon:{padding: iconPadding}} = allPaddings[size];
	return `${v}px ${iconEnd ? iconPadding : h}px ${v}px ${iconStart ? iconPadding : h}px`;
};

const getIconBackground = (name: IconNames, position: 'left' | 'right', margin: number, size: number=24, color: string='#000') => `url("${getIcon(name, color)}") no-repeat ${position} ${margin}px center / ${size}px`;

const getIconBackgrounds = (size: Size, color: string, iconStart?: IconDef, iconEnd?: IconDef) => [
		...(iconStart ? [getIconBackground(iconStart.name, 'left', allPaddings[size].icon.margin, allPaddings[size].icon.size, color)] : []),
		...(iconEnd ? [getIconBackground(iconEnd.name, 'right', allPaddings[size].icon.margin, allPaddings[size].icon.size, color)] : [])
	].join(',');

export const buttonContained = (props: Props) =>  {
	const {color, size, iconStart, iconEnd} = Object.assign({color: 'default', size: 'medium'}, props);

	return css<{selected: boolean}>`
		position: relative;
		min-width: 64px;
		padding: ${getPadding(size, !!iconStart, !!iconEnd)};
		color: ${({ theme }) => theme.palette[color].contrastText};
		${getTypography('button')};
		text-align: center;
		text-decoration: none;
		overflow: hidden;
		border-radius: 4px;
		border: none;
		background: ${({theme}) => getIconBackgrounds(size, theme.palette[color].contrastText, iconStart, iconEnd)};
		background-color: ${({ theme }) => theme.palette[color].main};
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
		cursor: pointer;
		outline: none;

		&:hover {
			background-color: ${({ theme }) => theme.palette[color].light};
			box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
		}
  `;
}



export const button = (initialProps: ButtonProps) => {
  const props = Object.assign({ variant: 'text', size: 'medium', color: 'default' }, initialProps);
  const backgrounds = [];

  const sizes = {
    small: css`
      padding: 4px ${props.iconRight ? '29px' : '5px'} 4px ${props.iconLeft ? '29px' : '5px'};
      font-size: 0.8125rem;
      line-height: 1.75;
    `,
    medium: css`
      padding: 5px ${props.iconRight ? '37px' : '15px'} 5px ${props.iconLeft ? '37px' : '15px'};
      font-size: 0.875rem;
      line-height: 1.75;
    `,
    large: css`
      padding: 10px 20px;
      font-size: 0.9375rem;
      line-height: 1.75;
    `
  }

  const variants = {
    text: css`
      border: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    `,
    outlined: css`
      border: 1px solid rgba(0, 0, 0, 0.23);
      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    `,
    contained: css`
      color: ${({ theme }) => theme.palette[props.color].contrastText};
      border: none;
      background-color: ${({ theme }) => theme.palette[props.color].main};
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

      &:hover {
        background-color: ${({ theme }) => theme.palette[props.color].light};
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
      }
    `
  }

  const iconSizes = { small: '18px', medium: '20px', large: '22px' };
  const iconMargins = { small: '4px', medium: '10px', large: '8px' };

  if (props.iconLeft) {
    backgrounds.push(`url("${getIcon(props.iconLeft.name, props.iconLeft.color)}") no-repeat left ${iconMargins[props.size]} center / ${iconSizes[props.size]}`);
  }
  if (props.iconRight) {
    backgrounds.push(`url("${getIcon(props.iconRight.name, props.iconRight.color)}") no-repeat right ${iconMargins[props.size]} center / ${iconSizes[props.size]}`);
  }

  return css<{selected: boolean}>`
    ${variants[props.variant]};
    ${sizes[props.size]};
    ${backgrounds.length > 0 && `background: ${backgrounds.join(',')}`};

		  position: relative;
  min-width: 64px;
  overflow: hidden;
  border-radius: 4px;
  ${({ theme }) => theme.typography.button};
  background-color: ${({ selected }) => selected ? 'rgba(0, 0, 0, 0.08)' : 'transparent'};
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  outline: none;
  `;
};