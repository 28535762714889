import { Section } from '@hamrolt/components';
import { ImageArticle } from '../components/ImageArticle';
import { Details } from '../components/Details';
import { db, doc } from '../firebase';

const dbInfo = doc(db, 'about/info');
const dbDetails = doc(db, 'about/details');

export const About = () =>
	<div>
		<Section title="O mnie">
			<ImageArticle res={{ base: dbInfo, localized: doc(dbInfo, 'localized/pl-PL') }} />
		</Section>
		<Section title="Dane kontaktowe">
			<Details res={{ base: dbDetails, localized: doc(dbDetails, 'localized/pl-PL') }} />
		</Section>
	</div>