import { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Switch, TextField } from '@hamrolt/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference } from '../firebase';

interface CommonData {
	published: boolean;
	label: string;
	url: string;
}

const defaultCommonData: CommonData = {
	published: false,
	label: '',
	url: ''
};

interface Resources {
	base: DocumentReference;
}

export interface Props {
	res: Resources;
	className?: string;
}

interface FormData {
	published: boolean;
	url: string;
};

const Form = styled.form`
	display: grid;
	grid-gap: 32px;
	align-items: end;
	grid-template-columns: 1fr 184px;
	margin-bottom: 16px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.iconButton({ size: 'small', icon: { name: 'save' } })};
`;

const PublishedSwitch = styled(Switch)`
	display: flex;
`;

export const SocialChannel = ({ res, className }: Props) => {
	const { data: common, update: updateCommon } = useDocument<CommonData>(res.base, defaultCommonData);

	const { register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ published, url }) => {
		updateCommon({ published, url });
	};

	useEffect(() => {
		setValue('published', common.published);
		setValue('url', common.url);
	}, [setValue, common]);

	return <Form className={className} onSubmit={handleSubmit(onSubmit)}>
		<TextField
			id="url"
			label={common.label}
			{...register('url')}
		/>
		<Actions>
			<SaveButton type="submit">Zapisz</SaveButton>
			<PublishedSwitch label="Opublikowany" {...register('published')} />
		</Actions>
	</Form>
}