import { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button, TextField } from '@hamrolt/components';
import { withRouter, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../AuthProvider';
import { auth, signInWithEmailAndPassword } from '../firebase';

interface FormData {
	login: string;
	password: string;
}

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Dialog = styled.div`
  width: 300px;
	padding: 16px;
	border-radius: 4px;
	background-color: #fafafa;
	border: 1px solid #eee;
`;

const Form = styled.form`
	display: grid;
  grid-gap: 16px;
`;

const Title = styled.div`
	${({ theme }) => theme.typography.heading4};
	margin-bottom: 16px;
`;

const Error = styled.div`
	${({ theme }) => theme.typography.body1};
	color: ${({ theme }) => theme.palette.error};
	margin-bottom: 16px;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const Actions = styled.div`
	display: grid;
	justify-content: center;
`;

const SubmitButton = styled(Button)`
	${({ theme }) => theme.buttonContained({ color: 'primary' })};
`;

export const Login = withRouter(({ history }) => {
	const { currentUser } = useContext(AuthContext);
	const [error, setError] = useState('');

	if (currentUser) {
		return <Redirect to='/' />;
	}

	const { register, handleSubmit } = useForm<FormData>();

	const onSubmit = (data: FormData) => {
		signInWithEmailAndPassword(auth, data.login, data.password)
			.then(() => history.push('/'))
			.catch(() => setError('Niepoprawne dane logowania'));
	};

	return <Container>
		<Dialog>
			<Title>Admin</Title>
			{error && <Error>{error}</Error>}
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Content>
					<TextField
						id="login"
						label="Użytkownik"
						{...register('login')}
					/>
					<TextField
						id="password"
						label="Hasło"
						{...register('password')}
					/>
				</Content>
				<Actions>
					<SubmitButton type='submit'>
						LOGIN
					</SubmitButton>
				</Actions>
			</Form>
		</Dialog>
	</Container>
});
