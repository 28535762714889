import styled from 'styled-components';
import { Section, Sortable } from '@hamrolt/components';
import { SocialChannel } from './SocialChannel';
import { useDocument } from '../hooks/useDocument';
import { doc, DocumentReference, CollectionReference } from '../firebase';

type Order = string[];

interface CommonData {
	order: Order;
}

export interface Props {
	res: {
		base: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

const defaultCommonData: CommonData = {
	order: []
};

const Portlet = styled.div`
`;

export const SocialChannels = ({ res, className }: Props) => {
	const { data: common, update: updateCommon } = useDocument<CommonData>(res.base, defaultCommonData);

	return <Section title="Social channels" className={className}>
		<Sortable
			id="channels"
			items={common.order}
			direction="vertical"
			onDragEnd={order => updateCommon({ order: (order as Order) })}
			renderItem={({ item: id }: any, { innerRef, ...provided }, snapshot) =>
				<Portlet
					{...provided}
					ref={innerRef}
				// $dragged={snapshot.isDragging}
				>
					<SocialChannel res={{ base: doc(res.items, id) }} />
				</Portlet>
			} />
	</Section>;
};