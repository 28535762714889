import { useState, useEffect, useCallback } from 'react';
import { usePopper } from 'react-popper';

export const useDropdown = () => {
  const [contentVisible, setContentVisible] = useState(false);
  const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLElement | null>(null);
  const { styles: {popper: contentStyle} } = usePopper(triggerRef, contentRef);

  const handleDocumentClick = useCallback((e: MouseEvent) => {
    if (!contentRef?.contains(e.target as Node) && !triggerRef?.contains(e.target as Node)) {
      setContentVisible(false);
    }
  }, [contentRef, triggerRef, setContentVisible]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, [contentRef, handleDocumentClick]);

  return {
		setContentVisible,
		setTriggerRef,
		setContentRef,
    contentVisible,
		contentStyle
  };
};