import styled from 'styled-components';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { Mentoring } from './pages/Mentoring';
import { Communication } from './pages/Communication';
import { About } from './pages/About';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { SaleStatute } from './pages/SaleStatute';
import { Settings } from './pages/Settings';
import { PrivateRoute } from './PrivateRoute';
import { Navigation } from './Navigation';
import { User } from './components/User';

const AppBar = styled.div`
	display: grid;
	grid-template-columns: auto 48px;
`;

const Main = styled.main`
  margin: 40px 0 0;
`;

export const App = () =>
	<div>
		<Switch>
			<Route path='/login'>
				<Login />
			</Route>
			<PrivateRoute path='/'>
				<AppBar>
					<Navigation />
					<User />
				</AppBar>
				<Main>
					<Route path='/' exact>
						<Redirect to='/mentoring' />
					</Route>
					<Route path='/home'>
						<Home />
					</Route>
					<Route path='/mentoring/:productId?'>
						<Mentoring />
					</Route>
					<Route path='/communication/:productId?'>
						<Communication />
					</Route>
					<Route path='/about'>
						<About />
					</Route>
					<Route path='/privacy-policy'>
						<PrivacyPolicy />
					</Route>
					<Route path='/sale-statute'>
						<SaleStatute />
					</Route>
					<Route path='/settings'>
						<Settings />
					</Route>
				</Main>
			</PrivateRoute>
		</Switch>
	</div>

export default App;
