import { Hero } from '../components/Hero';
import { Cooperation } from '../components/Cooperation';
import { Products } from '../components/Products';
import { Materials } from '../components/Materials';
import { db, doc, collection } from '../firebase';

const dbHero = doc(db, 'communication/hero');
const dbOffer = doc(db, 'communication/offer');
const dbCooperation = doc(db, 'communication/cooperation');
const dbMaterials = doc(db, 'communication/materials');

export const Communication = () =>
	<div>
		<Hero res={{ base: dbHero, items: collection(dbHero, 'items') }} />
		<Products res={{ base: dbOffer, localized: doc(dbOffer, 'localized/pl-PL'), items: collection(dbOffer, 'items') }} />
		<Cooperation res={{ base: dbCooperation, localized: doc(dbCooperation, 'localized/pl-PL') }} />
		<Materials res={{ base: dbMaterials, localized: doc(dbMaterials, 'localized/pl-PL'), items: collection(dbMaterials, 'items') }} />
	</div>