import { MouseEvent as ReactMouseEvent } from 'react';
import styled from 'styled-components';

export type Direction = 'nw' | 'n' | 'ne' | 'e' | 'm' | 'w' | 'se' | 's' | 'sw';
export type Position = 'start' | 'center' | 'end';
export type Definitions = Record<Direction, { position: { h: Position, v: Position }, label: string }>;

interface Props {
	items: Definitions;
	selection: Direction;
	onChange: (id: Direction, e: ReactMouseEvent<Element, MouseEvent>) => void;
}

const Container = styled.div`
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(3, 60px);
`;

const Button = styled.button<{ $selected: boolean; }>`
	${({ $selected, theme }) => theme.buttonContained({ color: $selected ? 'secondary' : 'default' })};
`;

export const Positioner = ({ items, selection, onChange }: Props) =>
	<Container>
		{Object.entries(items).map(([id, { label }]) =>
			<Button
				key={id}
				type="button"
				$selected={selection === id}
				onClick={e => onChange(id as Direction, e)}
			>
				{label}
			</Button>
		)}
	</Container>