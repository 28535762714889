import styled from 'styled-components';
import { Button } from '@hamrolt/components';
import { useDropdown } from '../hooks/useDropdown';
import { auth } from '../firebase';

const Avatar = styled(Button)`
	${({ theme }) => theme.iconButton({ icon: { name: 'account_circle' } })};
`;

const DropDownContent = styled.div`
	padding: 16px;
	border-radius: 4px;
	background-color: #fff;
	z-index: 100;
	${({ theme }) => theme.elevation.level3};
`;

const Option = styled.button<{ $selected: boolean; }>`
	${({ theme }) => theme.clickableListItem({ selected: { color: 'secondary' } })};
	margin: 0 -16px;
`;

export const User = () => {
	const {
		setContentVisible: setMenuVisible,
		setTriggerRef: setMenuTriggerRef,
		setContentRef: setMenuContentRef,
		contentVisible: menuContentVisible,
		contentStyle: menuContentStyle
	} = useDropdown();

	const logout = () => {
		auth.signOut()
			.then(() => {
				console.log('SIGNED OUT');
			})
			.catch((error) => console.log('SIGN OUT ERROR', error));

		setMenuVisible(false);
	};

	return <>
		<Avatar
			type="button"
			ref={setMenuTriggerRef}
			onClick={() => setMenuVisible(!menuContentVisible)}
		>
			I
		</Avatar>
		{menuContentVisible &&
			<DropDownContent ref={setMenuContentRef} style={menuContentStyle}>
				<Option type="button" $selected={false} onClick={() => logout()}>
					Wyloguj
				</Option>
			</DropDownContent>
		}
	</>
};
