export const getExtension = (type: string) => {
	switch (type) {
		case 'image/jpg':
		case 'image/jpeg':
			return 'jpg';

		case 'image/png':
			return 'png';

		case 'application/pdf':
			return 'pdf';

		default:
			console.warn('File EXTENSION has not been recognized');
			break;
	}
};