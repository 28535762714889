import { useEffect } from 'react';
import styled from 'styled-components';
import { Section, Button, /*Switch,*/ TextField } from '@hamrolt/components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ImageUpload } from './ImageUpload';
import { useFile, FileData, fileDefaultData } from '../hooks/useFile';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference } from '../firebase';

interface CommonData {
	published: boolean;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData
};

interface LocalizedData {
	heading: string;
	imageAlt: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	imageAlt: ''
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
	onRemove?: () => void;
}

interface FormData {
	published: boolean;
	heading: string;
	image: FileData;
	imageAlt: string;
};

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

const DeleteButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

// const PublishedSwitch = styled(Switch)`
// 	display: flex;
// `;

export const Cooperation = ({ res, className, onRemove }: Props) => {
	const { data: common, update: updateCommon, remove: removeCommon } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized, update: updateLocalized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const { update: updateImage, remove: removeFile } = useFile('image', res.base.path);

	const { control, register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ published, heading, image, imageAlt }) => {
		const imageData = await updateImage(image as FileData);
		updateCommon({ /*published,*/ image: imageData });
		updateLocalized({ heading, imageAlt });
	};

	const handleDelete = () => {
		removeCommon();
		console.log(common.image.url);
		if (common.image.path) {
			removeFile(common.image.path);
		}
		onRemove && onRemove();
	};

	useEffect(() => {
		// setValue('published', common.published);
		setValue('image', common.image);
		setValue('heading', localized.heading);
		setValue('imageAlt', localized.imageAlt);
	}, [setValue, common, localized]);

	return <Section title={localized.heading} className={className}>
		<Form className={className} onSubmit={handleSubmit(onSubmit)}>
			<Actions>
				<SaveButton type="submit">Zapisz</SaveButton>
				{onRemove && <DeleteButton type="button" onClick={handleDelete}>Usuń</DeleteButton>}
			</Actions>
			<TextField
				id="heading"
				label="Tytuł"
				{...register('heading')}
			/>
			<Controller
				name="image"
				control={control}
				render={({ field: { value, onChange } }) => <ImageUpload id="image" label="Ilustracja" value={value as FileData} onChange={onChange} />}
			/>
			<TextField
				id="imageAlt"
				label="Alt Text"
				{...register('imageAlt')}
			/>
			{/* <PublishedSwitch
			label="Opublikowany"
			{...register('published')}
		/> */}
		</Form>
	</Section>
}