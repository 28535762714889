import { css } from 'styled-components';
import { getIcon, IconNames } from './icons';
import { typography, getTypography } from './typography';
import { elevation } from './elevation';
import { rating } from './rating';
import { textfield } from './textfield';
import { textarea } from './textarea';
import { button, buttonContained } from './button';
import { iconButton } from './iconButton';
import { list, listItem, clickableListItem } from './list';

const palette = {
  primary: {
    main: '#809acf',
    dark: '#506c9e',
    light: '#b1cbff',
    contrastText: '#fff'
  },
  secondary: {
    main: '#754778',
    dark: '#481d4c',
    light: '#a573a7',
		contrastText: '#fff'
  },
	default: {
		main: '#e0e0e0',
		light: '#eee',
		dark: '#bdbdbd',
		contrastText: '#212121'
	},
	error: '#b00020'
};

const spacing = { xs: '2px', sm: '4px', md: '8px', lg: '16px', xl: '32px' };

export const theme = {
  palette,
  spacing,
  elevation,
  typography,
	getTypography,
  button,
	buttonContained,
  iconButton,
	list,
	listItem,
	clickableListItem,
  image: css`
    display: block;
  `,
  link: css`
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
  rating,
  textfield,
  textarea,
  border: {
    radius: '4px',
  },
  helpers: {
    getIcon: (name: IconNames) => `url("${getIcon(name)}")`,
    hideText: css`
      text-indent: -9999px;
      white-space: nowrap;
      overflow: hidden;
    `,
  },
};
