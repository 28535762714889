import { css } from 'styled-components';

//TODO typing
export const rating = (Star: any) => css`
	display: grid;
	width: 120px;
	margin: 0 auto 8px;
	grid-template-columns: repeat(5, 24px);

	${Star} {
		width: 24px;
		height: 24px;
		background: transparent url("${({ theme }) => theme.helpers.getIcon('star', '#ffb400')}");
	}
`;