import { Space } from '../components/Space';
import { Customers } from '../components/Customers';
import { Certificates } from '../components/Certificates';
import { Verbatims } from '../components/Verbatims';
import { Hero } from '../components/Hero';
import { About } from '../components/About';
import { db, doc, collection } from '../firebase';

const dbHero = doc(db, 'home/hero');
const dbAbout = doc(db, 'home/about');
const dbCertificates = doc(db, 'home/certificates');
const dbVerbatims = doc(db, 'home/verbatims');
const dbLogotypes = doc(db, 'home/logotypes');
const dbSpace = doc(db, 'home/space');

export const Home = () =>
	<div>
		<Hero res={{ base: dbHero, items: collection(dbHero, 'items') }} />
		<About res={{ base: dbAbout, localized: doc(dbAbout, 'localized/pl-PL') }} />
		<Certificates res={{ base: dbCertificates, localized: doc(dbCertificates, 'localized/pl-PL'), items: collection(dbCertificates, 'items') }} />
		<Verbatims res={{ base: dbVerbatims, localized: doc(dbVerbatims, 'localized/pl-PL'), items: collection(dbVerbatims, 'items') }} />
		<Customers res={{ base: dbLogotypes, localized: doc(dbLogotypes, 'localized/pl-PL'), items: collection(dbLogotypes, 'items') }} />
		<Space res={{ base: dbSpace, localized: doc(dbSpace, 'localized/pl-PL'), items: collection(dbSpace, 'items') }} />
	</div>