import { useEffect } from 'react';
import styled from 'styled-components';
import { Button, /*Switch,*/ TextField } from '@hamrolt/components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ImageUpload } from './ImageUpload';
import { useFile, FileData, fileDefaultData } from '../hooks/useFile';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference, CollectionReference } from '../firebase';

interface LocalizedData {
	heading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

interface FormData {
	published: boolean;
	heading: string;
	image: FileData;
};

export const setNewItem = (collRef: CollectionReference, locale: string) => {
	// const newDoc = collRef.doc();
	// newDoc.set(defaultCommonData);
	// newDoc.collection('localized').doc(locale).set(defaultLocalizedData);
	// return newDoc;
};

const Container = styled.div`
	padding: 16px;
	border-radius: 8px;
	background-color: #f4f4f4;
`;

const Form = styled.form`
	display: grid;
	grid-gap: 16px;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
`;

const SaveButton = styled(Button)`
	${({ theme }) => theme.button({ variant: 'outlined' })};
`;

// const PublishedSwitch = styled(Switch)`
// 	display: flex;
// `;

export const Poster = ({ res, className }: Props) => {
	const { data: common, update: updateCommon } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized, update: updateLocalized } =
		useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const { update: updateImage } = useFile('image', res.base.path);

	const { control, register, setValue, handleSubmit } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async ({ published, heading, image }) => {
		const imageData = await updateImage(image as FileData);
		updateCommon({ /*published,*/ image: imageData });
		updateLocalized({ heading });
	};

	useEffect(() => {
		setValue('heading', localized.heading);
		// setValue('published', common.published);
		setValue('image', common.image);
	}, [setValue, localized, common]);

	return <Container>
		<Form className={className} onSubmit={handleSubmit(onSubmit)}>
			<Actions>
				<SaveButton type="submit">Zapisz</SaveButton>
			</Actions>
			<Controller
				name="image"
				control={control}
				render={({ field: { value, onChange } }) => <ImageUpload id="image" label="Ilustracja" value={value as FileData} onChange={onChange} />}
			/>
			{/* <PublishedSwitch
			label="Opublikowany"
			{...register('published')}
		/> */}
			<TextField
				id="heading"
				label="Podpis"
				{...register('heading')}
			/>
		</Form>
	</Container>
}